import React from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./index.module.css";
import { RegionData } from "../../Constant/RegionData";
import { SectionData } from "../../Constant/SectionData";
import { updateExhibitorData } from "../../firebase";
import { CircularLoading } from "../../Constant/AppConstant";

function MyVerticallyCenteredModal(props) {
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      let obj = {};
      let formSelector = Array.from(
        document.querySelectorAll(`#exhibitorForm input, #exhibitorForm select`)
      );

      if (formSelector && formSelector.length) {
        for (const iterator of formSelector) {
          if (
            (!iterator.value || !iterator.value.trim()) &&
            iterator.required
          ) {
            alert(`Please enter valid ${iterator.name}.`);
            return;
          }
          obj[iterator.name] =
            iterator.name === "area" || iterator.name === "comapnyId"
              ? +iterator.value
              : iterator.value;
        }
      }
      let _d = { [obj["comapnyId"]]: { ...obj } };
      setLoading(true);

      await updateExhibitorData(_d);
      setLoading(false);
      props.onHide();
    } catch (error) {
      console.error(error);
      setLoading(false);
      alert(error?.message || "Some internal error ocurred.");
    }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Enter Exhibitor details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="exhibitorForm" onSubmit={handleSubmit}>
          <div className={styles.formInputContainer}>
            <label htmlFor={"comapnyId"}>Company ID</label>
            <input
              id={"comapnyId"}
              name={"comapnyId"}
              className={"form-control"}
              placeholder={"Please enter company id."}
              type={"number"}
              required={true}
              disabled={loading}
            />
          </div>
          <div className={styles.formInputContainer}>
            <label htmlFor={"company_name"}>Company Name</label>
            <input
              id={"company_name"}
              name={"company_name"}
              className={"form-control"}
              placeholder={"Please enter company name."}
              type={"text"}
              required={true}
              disabled={loading}
            />
          </div>
          <div className={styles.formInputContainer}>
            <label htmlFor={"section"}>Section</label>
            <select name="section" className={styles.languageSelect}>
              <option value="" disabled>
                Select Section
              </option>
              {Object.keys(SectionData).map((key, index) => (
                <option key={index} value={key}>
                  {SectionData[key]}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formInputContainer}>
            <label htmlFor={"region"}>Region</label>
            <select name="region" className={styles.languageSelect}>
              <option value="" disabled>
                Select Region
              </option>
              {Object.keys(RegionData).map((key, index) => (
                <option key={index} value={key}>
                  {RegionData[key]}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formInputContainer}>
            <label htmlFor={"area"}>Area</label>
            <input
              id={"area"}
              name={"area"}
              className={"form-control"}
              placeholder={"Please enter area"}
              type={"number"}
              required={true}
              disabled={loading}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={loading}
          className={styles.button}
          onClick={handleSubmit}
        >
          {loading ? (
            <img src={CircularLoading} alt="loading animation" />
          ) : (
            "Save"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

const ExhibitorForm = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={() => setModalShow(true)}>
        Add Exhibitor
      </button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default ExhibitorForm;