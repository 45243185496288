import React, { useContext, useEffect, useReducer, useRef } from 'react'
import { CircularLoading, LoaderAnimation } from '../../Constant/AppConstant';
import { DeleteSellersStallData, GetAllSellersStallData, GetSellersBaseData, GetSingleSellerStallData, logoutUser, realDB, UpdateSellerStallData } from '../../firebase';
import style from './index.module.css';
import Select from 'react-select';
import { RegionData } from '../../Constant/RegionData';
import { SectionData } from '../../Constant/SectionData';
import Fuse from 'fuse.js'
import { AreaOptionData, AREA_HALL_MAPPING, HALL_CONSTANT_DATA, HALL_KEYS_CONSTANT_DATA } from '../../Constant/AreaOptionData';
import { HallLayoutData } from '../../Constant/LayoutData';
import { UserContext } from '../../Context/UserContextProvider';
import { StallIdToStallName } from '../../util';
import { REALDB_CONSTANT } from '../../Constant/FirebaseConstant';
import ExhibitorForm from '../ExhibitorForm';
import { STALL_DESIGN_DATA, ZONE_DATA, PREMIUM_TYPE_DATA, VENDOR_DATA } from '../../Constant/evenuefyAPIData';
import { SellersDataToNotifyData } from '../../3rdParty';

const InitialState = {
    canSubmit: false,
    dataFetched: false,
    stallDataFetched: false,
    forceLoading: false,
    buttonLoading: false,
    showConfirmation: false,
    Database: {
        sellersBaseData: {},
        sellersStallData: {},
        stallData: {}
    },
    currentFormData: {
        sellerOptions: [],
        regionOptions: [],
        sectionOptions: [],
        areaOptions: [],
        hallOptions: [],
        stallOptions: [],
        zoneOptions: Object.keys(ZONE_DATA).map(_sId => ({ value: _sId, label: _sId })) || [],
        StallDesignOptions: Object.keys(STALL_DESIGN_DATA).map(_sId => ({ value: _sId, label: STALL_DESIGN_DATA[_sId] })) || [],
        premiumOptions: Object.keys(PREMIUM_TYPE_DATA).map(_sId => ({ value: _sId, label: PREMIUM_TYPE_DATA[_sId] })) || [],
        vendorOptions: Object.keys(VENDOR_DATA).map(_sId => ({ value: _sId, label: VENDOR_DATA[_sId].name })) || [],
        selectedSellerId: null,
        selectedRegion: null,
        selectedSection: null,
        selectedArea: null,
        selectedHall: null,
        selectedStall: null,
        selectedZone: null,
        selectedStallDesign: null,
        selectedPremiumType: null,
        selectedVendor: null,
    }
}
const ACTION = {
    FORCE_LOADING: "FORCE_LOADING",
    TOGGLE_SUBMIT: "TOGGLE_SUBMIT",
    TOGGLE_BUTTON_LOADING: "TOGGLE_BUTTON_LOADING",
    MARK_INITAL_DATA_FETCH: "MARK_INITAL_DATA_FETCH",
    MARK_STALL_DATA_FETCH: "MARK_STALL_DATA_FETCH",
    UPDATE_STALL_DATA: "UPDATE_STALL_DATA",
    ADD_SELLER_DATA_TO_DATABASE: "ADD_SELLER_DATA_TO_DATABASE",
    SELECT_SELLER: "SELECT_SELLER",
    ADD_REGION_OPTIONS: "ADD_REGION_OPTIONS",
    SELECT_REGION: "SELECT_REGION",
    ADD_SECTION_OPTIONS: "ADD_SECTION_OPTIONS",
    SELECT_SECTION: "SELECT_SECTION",
    ADD_AREA_OPTIONS: "ADD_AREA_OPTIONS",
    SELECT_AREA: "SELECT_AREA",
    GET_HALL_NO_ACCORDING_TO_AREA: "GET_HALL_NO_ACCORDING_TO_AREA",
    GET_STALLS_ACCORDING_TO_HALL: "GET_STALLS_ACCORDING_TO_HALL",
    SELECT_HALL: "SELECT_HALL",
    SELECT_STALL: "SELECT_STALL",
    SELECT_ZONE: "SELECT_ZONE",
    SELECT_STALL_DESIGN: "SELECT_STALL_DESIGN",
    SELECT_PREMIUM_TYPE: "SELECT_PREMIUM_TYPE",
    SELECT_VENDOR: "SELECT_VENDOR",
    SELECT_ZONE: "SELECT_ZONE",
    RESET: "REST",
    COMPLETE_RESET: "COMPLETE_RESET",
    TOGGLE_CONFIRMATION: "TOGGLE_CONFIRMATION"
}
const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case ACTION.TOGGLE_SUBMIT:
            return {
                ...state,
                canSubmit: payload
            }
        case ACTION.TOGGLE_BUTTON_LOADING:
            return {
                ...state,
                buttonLoading: payload
            }
        case ACTION.FORCE_LOADING:
            return {
                ...state,
                forceLoading: payload
            }
        case ACTION.MARK_INITAL_DATA_FETCH:
            return {
                ...state,
                dataFetched: true,
                Database: {
                    ...state.Database,
                    "sellersBaseData": {
                        ...payload
                    }
                },
                currentFormData: {
                    ...state.currentFormData,
                    sellerOptions: Object.keys(payload).map(_sId => ({ value: _sId, label: `${payload[_sId].company_name} (${_sId})` }))
                }
            }
        case ACTION.MARK_STALL_DATA_FETCH:
            return {
                ...state,
                stallDataFetched: true,
                Database: {
                    ...state.Database,
                    "stallData": {
                        ...payload
                    }
                },
            }
        case ACTION.UPDATE_STALL_DATA:
            let _initSellersStallDat = {
                ...state.Database.stallData
            }

            let _currentStallData = payload.stallData;
            let _currentStallId = Object.keys(_currentStallData)[0]

            let stallsKeys = Object.keys(_initSellersStallDat)
            let res = stallsKeys.find(_sId => {
                let _cId = _initSellersStallDat[_sId].comapnyId
                if (_cId == payload.comapnyId) {
                    return true
                }
            })
            if (res != -1) {
                delete _initSellersStallDat[res];
            }


            return {
                ...state,
                stallDataFetched: true,
                Database: {
                    ...state.Database,
                    "stallData": {
                        ..._initSellersStallDat,
                        [_currentStallId]: payload
                    }
                },
            }
        case ACTION.ADD_SELLER_DATA_TO_DATABASE:
            return {
                ...state,
                Database: {
                    ...state.Database,
                    "sellersStallData": {
                        ...state.Database.sellersStallData,
                        [payload.id]: {
                            ...payload.data
                        }
                    }
                }
            }
        case ACTION.SELECT_SELLER:
            let _sellerId = payload.value
            // AutoRegionSelect
            let _selectedRegion = null
            if (state.Database.sellersBaseData[_sellerId].region) {
                let _regionId = state.Database.sellersBaseData[_sellerId].region;
                if (RegionData.hasOwnProperty(_regionId))
                    _selectedRegion = { value: _regionId, label: RegionData[_regionId] }
            }
            // AutoSectionSelect
            let _selectedSection = null
            if (state.Database.sellersBaseData[_sellerId].section) {
                let _sectionId = state.Database.sellersBaseData[_sellerId].section;
                if (SectionData.hasOwnProperty(_sectionId))
                    _selectedSection = { value: _sectionId, label: SectionData[_sectionId] }
            }

            // AutoPickSeller Data
            let _selectedArea = null
            let _selectedHall = null
            let _selectedStall = null
            if (state.Database.sellersStallData[_sellerId]) {

                // -- hall
                let stallData = state.Database.sellersStallData[_sellerId].stallData
                if (stallData) {
                    let _firstStallInfo = stallData[Object.keys(stallData)[0]]
                    _selectedHall = { value: _firstStallInfo.hallNo, label: HALL_CONSTANT_DATA[_firstStallInfo.hallNo] }
                    _selectedStall = { value: _firstStallInfo.stallId, label: _firstStallInfo.stallId }
                    // --area
                    let _area = _firstStallInfo.area
                    _selectedArea = { value: _area, label: AreaOptionData[_area] }
                }
            }
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    selectedSellerId: payload,
                    selectedRegion: _selectedRegion,
                    selectedSection: _selectedSection,
                    selectedArea: _selectedArea,
                    selectedHall: _selectedHall,
                    selectedStall: _selectedStall
                }
            }
        case ACTION.ADD_REGION_OPTIONS:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    regionOptions: payload
                }
            }
        case ACTION.SELECT_REGION:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    selectedRegion: payload,
                }
            }
        case ACTION.ADD_SECTION_OPTIONS:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    sectionOptions: payload
                }
            }
        case ACTION.SELECT_SECTION:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    selectedSection: payload,
                    selectedHall: null,
                    stallOptions: []
                }
            }

        case ACTION.ADD_AREA_OPTIONS:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    areaOptions: payload
                }
            }
        case ACTION.SELECT_AREA:

            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    selectedArea: payload,
                    selectedStall: null,
                    selectedHall: null,
                    selectedZone: null,
                    selectedStallDesign: null,
                    selectedPremiumType: null,
                    selectedVendor: null,
                }
            }
        case ACTION.GET_HALL_NO_ACCORDING_TO_AREA:
            let _area = state.currentFormData.selectedArea.value;
            let _hallOptions = null;
            if (AREA_HALL_MAPPING[_area]) {
                _hallOptions = AREA_HALL_MAPPING[_area].map(_hid => ({ value: _hid, label: HALL_CONSTANT_DATA[_hid] }))
            }
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    hallOptions: _hallOptions,
                    // selectedHall: null,
                }
            }
        case ACTION.SELECT_HALL:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    selectedHall: payload,
                    selectedStall: null,
                    selectedZone: null,
                    selectedStallDesign: null,
                    selectedPremiumType: null,
                    selectedVendor: null,
                }
            }
        case ACTION.GET_STALLS_ACCORDING_TO_HALL:
            let _stallArea = state.currentFormData.selectedArea.value;
            let _hall = state.currentFormData.selectedHall.value;
            let _stallOption = [];
            if (HallLayoutData[_hall]) {
                let _initSellersStallDat = {
                    ...state.Database.stallData
                }
                let bookedStallIds = Object.keys(_initSellersStallDat);
                HallLayoutData[_hall].filter((_d) => _d.section === state.currentFormData.selectedSection.value).forEach(_stallData => {
                    let _index = bookedStallIds.indexOf(_stallData.stallId)
                    let _booked_butFromDifferentCompany = (_index != -1 && state.currentFormData.selectedSellerId.value == _initSellersStallDat[bookedStallIds[_index]].comapnyId)
                    if (_stallData.area == _stallArea && (_index == -1 || _booked_butFromDifferentCompany)) {
                        _stallOption.push({ value: _stallData.stallId, label: _stallData.stallId, payload: _stallData })
                    }
                })
            }
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    stallOptions: _stallOption,
                    // selectedStall: null,
                }
            }
        case ACTION.SELECT_STALL:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    selectedStall: payload
                }
            }
        case ACTION.SELECT_ZONE:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    selectedZone: payload
                }
            }
        case ACTION.SELECT_STALL_DESIGN:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    selectedStallDesign: payload
                }
            }
        case ACTION.SELECT_PREMIUM_TYPE:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    selectedPremiumType: payload
                }
            }
        case ACTION.SELECT_VENDOR:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    selectedVendor: payload
                }
            }
        case ACTION.RESET:
            return {
                ...state,
                currentFormData: {
                    ...state.currentFormData,
                    selectedArea: null,
                    selectedHall: null,
                    selectedStall: null,
                    selectedZone: null,
                    selectedStallDesign: null,
                    selectedPremiumType: null,
                    selectedVendor: null,
                }
            }
        case ACTION.COMPLETE_RESET:
            return {
                ...state,
                showConfirmation: false,
                currentFormData: {
                    ...state.currentFormData,
                    selectedSellerId: null,
                    selectedRegion: null,
                    selectedSection: null,
                    selectedArea: null,
                    selectedHall: null,
                    selectedStall: null,
                    selectedZone: null,
                    selectedStallDesign: null,
                    selectedPremiumType: null,
                    selectedVendor: null,
                }
            }
        case ACTION.TOGGLE_CONFIRMATION:
            return {
                ...state,
                showConfirmation: payload
            }
        default:
            console.error("NO ACTION TYPE FOUND")
        // throw new Error();
    }
}

export default function SellerForm() {
    const [state, dispatch] = useReducer(reducer, InitialState)
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (state.currentFormData.selectedArea) {
            dispatch({ type: ACTION.GET_HALL_NO_ACCORDING_TO_AREA })
        }
    }, [state.currentFormData.selectedArea])


    useEffect(() => {
        if (state.currentFormData.selectedHall) {
            dispatch({ type: ACTION.GET_STALLS_ACCORDING_TO_HALL })
        }
    }, [state.currentFormData.selectedHall])


    useEffect(() => {
        dispatch({ type: ACTION.TOGGLE_SUBMIT, payload: state.currentFormData.selectedStall ? true : false })
    }, [state.currentFormData.selectedStall])


    useEffect(() => {
        GetSellersBaseData().then(data => {
            // console.log(data);
            dispatch({
                type: ACTION.MARK_INITAL_DATA_FETCH,
                payload: data
            });
        }).catch(err => {
            console.error("in GetSellersBaseData")
            console.error(err)
        })

        GetAllSellersStallData
            ().then(data => {
                let _stallData = {}
                Object.keys(data).map(k => {
                    let _sellerData = data[k];
                    let _stallsData = _sellerData.stallData
                    Object.keys(_stallsData).forEach((stallId) => {
                        _stallData[stallId] = {
                            ..._sellerData,
                            currentStallData: _stallsData[stallId]
                        }
                    })
                });
                dispatch({
                    type: ACTION.MARK_STALL_DATA_FETCH,
                    payload: _stallData
                });
            }).catch(err => {
                console.error("in GetAllSellersStallData")
                console.error(err)
            })

        realDB.ref(REALDB_CONSTANT.Sellersdata).on("child_changed", (snaapshot) => {
            console.log("s_cc", snaapshot.val())
            if (snaapshot.exists()) {
                dispatch({
                    type: ACTION.UPDATE_STALL_DATA,
                    payload: snaapshot.val()
                });
            }
        });
        realDB.ref(REALDB_CONSTANT.Sellersdata).on("child_added", (snaapshot) => {
            // console.log("s_ca", snaapshot.val())
            if (snaapshot.exists()) {
                dispatch({
                    type: ACTION.UPDATE_STALL_DATA,
                    payload: snaapshot.val()
                });
            }
        });

        dispatch({
            type: ACTION.ADD_REGION_OPTIONS,
            payload: Object.keys(RegionData).map(_rId => ({ value: _rId, label: RegionData[_rId] }))
        });

        dispatch({
            type: ACTION.ADD_SECTION_OPTIONS,
            payload: Object.keys(SectionData).map(_sId => ({ value: _sId, label: SectionData[_sId] }))
        });

        dispatch({
            type: ACTION.ADD_AREA_OPTIONS,
            payload: Object.keys(AreaOptionData).map(_aId => ({ value: _aId, label: AreaOptionData[_aId] }))
        });

    }, [])



    const getSellersStallDataFromLocal = (sellerId) => {
        if (state.Database.sellersStallData.hasOwnProperty(sellerId)) {
            return state.Database.sellersStallData[sellerId];
        } else {
            return null
        }
    }
    const getSellersStallData = (sellerId) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (state.Database.sellersStallData.hasOwnProperty(sellerId)) {
                    resolve(state.Database.sellersStallData[sellerId])
                }
                const _data = await GetSingleSellerStallData(sellerId);
                if (_data) {
                    dispatch({
                        type: ACTION.ADD_SELLER_DATA_TO_DATABASE,
                        payload: {
                            id: sellerId,
                            data: _data
                        }
                    });
                    resolve(_data)
                } else {
                    resolve(null)
                }
            } catch (error) {
                console.error(error);
                reject(error);
            }
        })
    }

    const handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        dispatch({
            type: ACTION.TOGGLE_CONFIRMATION,
            payload: true
        });
    }

    const saveData = async () => {
        try {
            dispatch({ type: ACTION.TOGGLE_BUTTON_LOADING, payload: true })
            let _currentFormData = state.currentFormData
            let _sellerId = _currentFormData.selectedSellerId.value
            let _sellerBaseData = state.Database.sellersBaseData[_sellerId];
            let _sellerData = {
                comapnyId: _sellerId,
                company_name: _sellerBaseData.company_name,
                region: _currentFormData.selectedRegion.value,
                section: _currentFormData.selectedSection.value,
                stallData: {
                    [_currentFormData.selectedStall.value]: {
                        area: _currentFormData.selectedArea.value,
                        hallNo: _currentFormData.selectedHall.value,
                        stallId: _currentFormData.selectedStall.value,
                        section: _currentFormData.selectedSection.value,
                        // zone: _currentFormData.selectedZone.value,
                        // stallType: _currentFormData.selectedStallDesign.value,
                        // premiumType: _currentFormData.selectedPremiumType.value,
                        // vendor: VENDOR_DATA[_currentFormData.selectedVendor.value]
                    }
                }
            }
            // console.log(_sellerId, _sellerData)
            // console.log(SellersDataToNotifyData(_sellerData))
            await UpdateSellerStallData(_sellerId, _sellerData, user);
            dispatch({ type: ACTION.TOGGLE_BUTTON_LOADING, payload: false })
            dispatch({ type: ACTION.COMPLETE_RESET, payload: false })

            alert("Done");
        } catch (error) {
            console.error(error)
            dispatch({ type: ACTION.TOGGLE_BUTTON_LOADING, payload: false })
            dispatch({ type: ACTION.TOGGLE_BUTTON_LOADING, payload: false })
            if (error.code == "API_ERROR") {
                alert(`${error.data.message} .Please try again.`);
            } else {
                alert("Something has gone wrong. Please try again.");
            }
        }
    }

    const handleResetStallData = async (event) => {
        if (event) {
            event.preventDefault();
        }
        dispatch({ type: ACTION.TOGGLE_BUTTON_LOADING, payload: true })
        try {
            //EDIT
            await DeleteSellersStallData(state.currentFormData.selectedSellerId.value, state.currentFormData.selectedSection.value, user);
            dispatch({ type: ACTION.RESET })
            // let _currentFormData = state.currentFormData
            // let _sellerId = _currentFormData.selectedSellerId.value
            // let _sellerBaseData = state.Database.sellersBaseData[_sellerId];
            // let _sellerData = {
            //     comapnyId: _sellerId,
            //     company_name: _sellerBaseData.company_name,
            //     region: _currentFormData.selectedRegion.value,
            //     section: _currentFormData.selectedSection.value,
            //     stallData: {
            //         [_currentFormData.selectedStall.value]: {
            //             area: _currentFormData.selectedArea.value,
            //             hallNo: _currentFormData.selectedHall.value,
            //             stallId: _currentFormData.selectedStall.value,
            //         }
            //     }
            // }
            // await UpdateSellerStallData(_sellerId, _sellerData);
            dispatch({ type: ACTION.TOGGLE_BUTTON_LOADING, payload: false })
            alert("Done");
        } catch (error) {
            console.log(error)
            dispatch({ type: ACTION.TOGGLE_BUTTON_LOADING, payload: false })
            if (error.code == "API_ERROR") {
                alert(`${error.data.message} .Please try again.`);
            } else {
                alert("Something has gone wrong. Please try again.");
            }
        }

    }

    const handleSelectChange = async (dispatchAction, _selectedOption) => {
        console.log(dispatchAction, _selectedOption)
        if (dispatchAction == ACTION.SELECT_SELLER) {
            if (getSellersStallDataFromLocal(_selectedOption.value)) {
                dispatch({ type: dispatchAction, payload: _selectedOption })
            } else {
                dispatch({
                    type: ACTION.FORCE_LOADING,
                    payload: true
                });
                await getSellersStallData(_selectedOption.value)
                dispatch({
                    type: ACTION.FORCE_LOADING,
                    payload: false
                });
                dispatch({ type: dispatchAction, payload: _selectedOption })
            }
        } else {
            dispatch({ type: dispatchAction, payload: _selectedOption })
        }
    }
    const { selectedSellerId, sellerOptions,
        selectedRegion, regionOptions,
        sectionOptions, selectedSection,
        selectedArea, areaOptions,
        selectedHall, hallOptions,
        selectedStall, stallOptions,
        zoneOptions, selectedZone,
        selectedStallDesign,
        selectedPremiumType,
        selectedVendor,
        StallDesignOptions,
        premiumOptions,
        vendorOptions,
        showConfirmation } = state.currentFormData
    return (
        <>
            <div className={style.formPageContainer}>
                <div className={style.formBody}>
                    {
                        (!(state.dataFetched && state.stallDataFetched) || state.forceLoading)
                        &&
                        <div className={style.loader}>
                            <img src={LoaderAnimation} alt="loading animation" />
                        </div>
                    }

                    <div className={style.header}>
                        <h3>
                            Seller Dashboard
                        </h3>
                        <div className={style.userInfo}>
                            <span>
                                {
                                    user.email.split("@")[0]
                                }
                            </span>
                            <br></br>
                            <span onClick={(e) => {
                                e.preventDefault();
                                logoutUser()
                            }}>
                                Logout
                            </span>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        {/* Seller Name */}
                        <div className={style.formInputContainer}>
                            <Select
                                value={selectedSellerId}
                                onChange={(_so) => handleSelectChange(ACTION.SELECT_SELLER, _so)}
                                options={sellerOptions}
                                placeholder="Enter Seller Name"
                                isSearchable
                                isDisabled={state.buttonLoading}
                            />
                        </div>

                        {/* Region Name */}
                        <div className={style.formInputContainer}>
                            {
                                regionOptions.length > 0 &&
                                <Select
                                    value={selectedRegion}
                                    onChange={(_so) => handleSelectChange(ACTION.SELECT_REGION, _so)}
                                    options={regionOptions}
                                    placeholder="Enter Region Name"
                                    isSearchable
                                    isDisabled={!selectedSellerId || state.buttonLoading}
                                />
                            }
                        </div>

                        {/* Section Name */}
                        <div className={style.formInputContainer}>
                            {
                                sectionOptions.length > 0 &&
                                <Select
                                    value={selectedSection}
                                    onChange={(_so) => handleSelectChange(ACTION.SELECT_SECTION, _so)}
                                    options={sectionOptions}
                                    placeholder="Enter Section Name"
                                    isSearchable
                                    isDisabled={!selectedSellerId || state.buttonLoading}
                                />
                            }
                        </div>

                        {/* Area */}
                        <div className={style.formInputContainer}>
                            {
                                areaOptions.length > 0 &&
                                <Select
                                    value={selectedArea}
                                    onChange={(_so) => handleSelectChange(ACTION.SELECT_AREA, _so)}
                                    options={areaOptions}
                                    placeholder="Select Area"
                                    isSearchable
                                    isDisabled={!selectedSellerId || state.buttonLoading}
                                />
                            }
                        </div>

                        {/* Hall No */}
                        <div className={style.formInputContainer}>
                            {
                                <Select
                                    value={selectedHall}
                                    onChange={(_so) => handleSelectChange(ACTION.SELECT_HALL, _so)}
                                    options={hallOptions}
                                    placeholder="Select Hall Number"
                                    isSearchable
                                    isDisabled={!selectedArea || state.buttonLoading}
                                />
                            }
                        </div>

                        {/* Stall No */}
                        <div className={style.formInputContainer}>
                            {
                                <Select
                                    value={selectedStall}
                                    onChange={(_so) => handleSelectChange(ACTION.SELECT_STALL, _so)}
                                    options={stallOptions}
                                    placeholder="Select Stall Number"
                                    isSearchable
                                    isDisabled={!selectedHall || state.buttonLoading}
                                />
                            }
                        </div>

                        {/* Zone */}
                        {/* <div className={style.formInputContainer}>
                            {
                                <Select
                                    value={selectedZone}
                                    onChange={(_so) => handleSelectChange(ACTION.SELECT_ZONE, _so)}
                                    options={zoneOptions}
                                    placeholder="Select Zone"
                                    isSearchable
                                    isDisabled={!selectedStall || state.buttonLoading}
                                />
                            }
                        </div> */}


                        {/* Stall Design */}
                        {/* <div className={style.formInputContainer}>
                            {
                                <Select
                                    value={selectedStallDesign}
                                    onChange={(_so) => handleSelectChange(ACTION.SELECT_STALL_DESIGN, _so)}
                                    options={StallDesignOptions}
                                    placeholder="Select Stall Design"
                                    isSearchable
                                    isDisabled={!selectedZone || state.buttonLoading}
                                />
                            }
                        </div> */}

                        {/* Premium */}
                        {/* <div className={style.formInputContainer}>
                            {
                                <Select
                                    value={selectedPremiumType}
                                    onChange={(_so) => handleSelectChange(ACTION.SELECT_PREMIUM_TYPE, _so)}
                                    options={premiumOptions}
                                    placeholder="Select Premium"
                                    isSearchable
                                    isDisabled={!selectedStallDesign || state.buttonLoading}
                                />
                            }
                        </div> */}

                        {/* Vendor */}
                        {/* <div className={style.formInputContainer}>
                            {
                                <Select
                                    value={selectedVendor}
                                    onChange={(_so) => handleSelectChange(ACTION.SELECT_VENDOR, _so)}
                                    options={vendorOptions}
                                    placeholder="Select Vendor"
                                    isSearchable
                                    isDisabled={!selectedPremiumType || state.buttonLoading}
                                />
                            }
                        </div> */}

                        <div className={style.buttonContainer}>
                            {
                                state.currentFormData.selectedSellerId && state.currentFormData.selectedSection &&
                                <button className={style.submitButton} type='button' onClick={(e) => {
                                    e.preventDefault();
                                    handleResetStallData();
                                }}>
                                    {
                                        state.buttonLoading &&
                                        <img src={CircularLoading} alt="loading animation" />
                                    }
                                    <span style={state.buttonLoading ? { visibility: "hidden" } : {}}>
                                        Reset Data
                                    </span>
                                </button>
                            }
                            <button className={style.submitButton} type='submit' disabled={!state.canSubmit || state.buttonLoading}>
                                {
                                    state.buttonLoading &&
                                    <img src={CircularLoading} alt="loading animation" />
                                }
                                <span style={state.buttonLoading ? { visibility: "hidden" } : {}}>
                                    Save
                                </span>
                            </button>
                        </div>
                    </form>
                    <ExhibitorForm />
                </div>
            </div>
            {
                state.showConfirmation &&
                <div className={style.confirmationContainer}>
                    <div className={style.confirmationBox}>
                        <div className={style.heading}>
                            Want to update following data ?
                        </div>
                        <div className={style.details}>
                            <div className={style.NameContainer}>
                                <span>
                                    {
                                        state.Database.sellersBaseData[state.currentFormData.selectedSellerId.value].company_name
                                    }
                                </span>
                                <span>
                                    {
                                        HALL_CONSTANT_DATA[state.currentFormData.selectedHall.value]
                                    }
                                </span>
                            </div>
                            <div className={style.stallNoContainer}>
                                Stall No. : &nbsp;
                                {
                                    StallIdToStallName(state.currentFormData.selectedStall.value)
                                }
                            </div>
                            <div className={style.sectionContainer}>
                                Section : &nbsp;
                                {SectionData[state.currentFormData.selectedSection.value]}
                            </div>
                        </div>
                        <div className={style.btnHolder}>
                            <button className={style.submitButton} disabled={!state.canSubmit || state.buttonLoading} onClick={(e) => {
                                e.preventDefault();
                                dispatch({
                                    type: ACTION.TOGGLE_CONFIRMATION,
                                    payload: false
                                });
                            }}>
                                {
                                    state.buttonLoading &&
                                    <img src={CircularLoading} alt="loading animation" />
                                }
                                <span style={state.buttonLoading ? { visibility: "hidden" } : {}}>
                                    Cancel
                                </span>
                            </button>
                            <button className={style.submitButton} disabled={!state.canSubmit || state.buttonLoading} onClick={(e) => {
                                e.preventDefault();
                                saveData();
                            }}>
                                {
                                    state.buttonLoading &&
                                    <img src={CircularLoading} alt="loading animation" />
                                }
                                <span style={state.buttonLoading ? { visibility: "hidden" } : {}}>
                                    Submit
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
