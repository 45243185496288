export const ZONE_DATA = {
  "1A": "1A",
  "1B": "1B",
  "1C": "1C",
  "1D": "1D",
  "2A": "2A",
  "2B": "2B",
  "3A": "3A",
  "3B": "3B",
  "4A": "4A",
  "4B": "4B",
  "5A": "5A",
  "5B": "5B",
  J1: "J1",
  J2: "J2",
  J3: "J3",
  J4: "J4",
  CLUB: "CLUB",
};

export const STALL_DESIGN_DATA = {
  ONESIDE_OPENSTALL: "One Side Open Stall",
  TWOSIDE_OPENSTALL: "Two Side Open Stall",
  THREESIDE_OPENSTALL: "Three Side Open Stall",
  FOURSIDE_OPENSTALL: "Four Side Open Stall",
  // PREMIUM: "Premium",
};

export const PREMIUM_TYPE_DATA = {
  ONESIDE_OPENSTALL: "One Side Open Stall",
  TWOSIDE_OPENSTALL: "Two Side Open Stall",
  THREESIDE_OPENSTALL: "Three Side Open Stall",
  FOURSIDE_OPENSTALL: "Four Side Open Stall",
  PREMIUM: "Premium",
};

export const VENDOR_DATA = {
  vendor1: {
    name: "Shri Balaji Exims",
    phone: "919310502225",
  },
  vendor2: {
    name: "Pavilions N Interiors",
    phone: "917290060797",
  },
  vendor3: {
    name: "N K Kapur",
    phone: "919711130877",
  },
  vendor4: {
    name: "Expro Events",
    phone: "919958091702",
  },
  vendor5: {
    name: "Pnim",
    phone: "918376090406",
  },
  vendor6: {
    name: "Balaji Exim - The Select Club",
    phone: "911122334455",
  },
};