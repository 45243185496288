import React, { useState, useEffect } from "react";
import styles from "./index.module.css";

const Splash = ({ infinite = false }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!infinite) {
        setIsVisible(false);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`${styles.container}`}>
      <img
        className={styles.logo}
        src={require("../../assets/images/splashLogo.png")}
        alt=""
      />
    </div>
  );
};

export default Splash;
