import axios from "axios"
import { LAYOUT_VENU } from "../Constant/LayoutData"
import { SectionData } from "../Constant/SectionData"
const allocatEndpoint = process.env.REACT_APP_EVENUEFY_ALLOCATE_ENDPOINT
const eventId = process.env.REACT_APP_EVENUEFY_EVENTID
const token = process.env.REACT_APP_EVENUEFY_TOKEN

console.log(allocatEndpoint)

const stallIdProcess = (stallId) => stallId.replace("Stall", "").replace("_", " ")


const isStaging = process.env.NODE_ENV ==="development";
const stagingData = {
    "bpNumber": "54214521", //7000009264
    "sectionName":  SectionData["sectionData_1"] ,
}


// bp : 7000075770
// section : Silver Jewellery, Artefacts & Gifting Items


// bp : 7000009264
// section : Silver Jewellery, Artefacts & Gifting Items


// "sectionName": "Diamond, Gemstone, and Other Studded Jewellery",
    // "bpNumber": "54214521",


    // {
    //     "eventId": "66225164bf87c357da335813",
    //     "sectionName": "Diamond, Gemstone, and Other Studded Jewellery",
    //     "bpNumber": "54214521",
    //     "boothName": "PUSHPA JEWELLERS PRIVATE LIMITED",
    //     "boothNumber": "CLUB 2A",
    //     "hallName": "JWCC",
    //     "zone": "1A",
    //     "venue": "JWCC",
    //     "stallDesing": "ONESIDE_OPENSTALL",
    //     "serviceContactNumber": "917290060797",
    //     "allocatedPremiumType": "ONESIDE_OPENSTALL",
    //     "allocatedStallArea": "24"
    // }

export const SellersDataToNotifyData = (sellerData) => {
    // ##SelerDataSample
    // let _sellerData = {
    //     comapnyId: _sellerId,
    //     company_name: _sellerBaseData.company_name,
    //     region: _currentFormData.selectedRegion.value,
    //     section: _currentFormData.selectedSection.value,
    //     stallData: {
    //         [_currentFormData.selectedStall.value]: {
    //             area: _currentFormData.selectedArea.value,
    //             hallNo: _currentFormData.selectedHall.value,
    //             stallId: _currentFormData.selectedStall.value,
    //             section: _currentFormData.selectedStall.payload.section,
    //         }
    //     }
    // }

    // ##Notify Data Sample
    // {
    //     "sectionName": "",
    //     "bpNumber": "",
    //     "boothName": "",
    //     "boothNumber": "",
    //     "hallName": "",
    //     "stallZone": "",
    //     "stallVenue": "",
    //     "serviceContactNumber": "+919737534875",
    //     "allocatedPremiumType": "",
    //     "allocatedStallArea": ""
    // }

    let bpNumber = sellerData.comapnyId
    let boothName = sellerData.company_name
    let stallId = Object.keys(sellerData.stallData)[0]
    let _stallData = sellerData.stallData[stallId]
    let stallArea = _stallData["area"]
    let hallNo = _stallData["hallNo"]
    let section =  SectionData[_stallData["section"]] 
    let zone = _stallData["zone"]
    let stallType = _stallData["stallType"]
    let serviceContactNumber = _stallData["vendor"].phone
    let premiumType = _stallData["premiumType"]
    return ({
        "sectionName": isStaging ? stagingData.sectionName : section,
        "bpNumber": isStaging ? stagingData.bpNumber : `${bpNumber}`,

        "boothName": boothName,
        "boothNumber": stallIdProcess(stallId),
        "hallName": hallNo,
        "zone": zone,
        "venue": LAYOUT_VENU,
        "stallDesing": stallType,
        "serviceContactNumber": serviceContactNumber,


        "allocatedPremiumType": premiumType,
        "allocatedStallArea": stallArea
    }
    )
}

const allocated = (payload) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios.post(allocatEndpoint, {
                "eventId": eventId,
                ...payload
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    "Accept":"*"
                }
            })
            resolve(data)
        } catch (error) {
            console.error(error)
            reject(error)
        }
    })
}

const revokeEndpoint = process.env.REACT_APP_EVENUEFY_REVOKE_ENDPOINT
console.log(revokeEndpoint)
const revoked = (bpNumber, section) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios.post(revokeEndpoint, {
                "eventId": eventId,
                "sectionName": isStaging ? stagingData.sectionName : SectionData[section],
                "bpNumber": isStaging ? stagingData.bpNumber : `${bpNumber}`,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    "Accept":"*"
                }
            })
            resolve(data)
        } catch (error) {
            console.error(error)
            reject(error)
        }
    })
}

export const NotifyEvenuefy = {
    allocated,
    revoked,
    SellersDataToNotifyData
}